<template>
	<main class="padding-t-24 bp-1024:padding-t-64">
		<slideshow
			class="border-b margin-b-24
				bp-768:border-b-none
				bp-1024:margin-b-72"
			template="SlideshowSlider"
			section-title="Top Slider"
			:slideshow-id="3"
			:count="5"
		/>
		<championships-list-filter
			title="NCAA Championship Tickets"
			:championships="championships"
			:defaultSeason="current_season"
			class="padding-x-24
				bp-1024:padding-x-64"
			@updateFilter="(filterResult) => filtered_championships = filterResult"
		/>
		<loading-indicator vertical-space="500" v-if="loading" />
		<transition name="fade">
			<div
				class="bp-768:padding-x-8
					bp-1024:padding-x-48"
				v-if="!loading"
			>
				<championships-list
					:data="sort_championships(filtered_championships)"
					section-title="Test"
					:show-tile-overlay-text="true"
				/>
			</div>
		</transition>
	</main>
</template>

<script>
import { get, sort_by } from '@/helpers';
import ChampionshipsList from '@/components/championships/templates/ChampionshipsList';
import ChampionshipsListFilter from '@/components/championships/ChampionshipsListFilter';
import Slideshow from '@/components/slideshow/Slideshow';
import LoadingIndicator from '@/components/common/LoadingIndicator';

export default {
	name: 'Championships',

	components: {
		ChampionshipsList,
		ChampionshipsListFilter,
		LoadingIndicator,
		Slideshow,
	},

	data: () => ({
		championships: [],
		filtered_championships: [],
		current_season: 'all',
		loading: false,
	}),

	methods: {
		get_championships() {
			this.loading = true;

			const request_options = {
				type: 'championships',
			};

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			).then(response => {
				this.championships = response.championships;
				this.filtered_championships = this.championships;
				this.current_season = response.current_season;
				this.loading = false;
			});
		},

		sort_championships(championships) {
			let _championships = championships.slice();

			if (this.sortOrder === 'division') {
				return _championships.sort(sort_by('division'));
			}

			return _championships
				.sort(sort_by('featured'))
				.sort(sort_by('title'));
		},

		async get_settings() {
			var response = await fetch(
				`${this.$root.proxy}/services/championships.ashx/get_settings?type=home`,
			);
			var { data } = await response.json();

			return data;
		},
	},

	async created() {
		let settings = await this.get_settings();
		let title = settings.metadata && settings.metadata.title && settings.metadata.title.length > 0
			? settings.metadata.title
			: `NCAA Championships`;
		let description = settings.metadata && settings.metadata.description && settings.metadata.description.length > 0
			? settings.metadata.description
			: `Buy and sell NCAA Championship tickets for every game on the road to the NCAA title. Includes hospitality options, dates, schedules and locations.`;
		let image = (settings.metadata && settings.metadata.image && settings.metadata.image.length > 0)
			? "banana" //this.$root.get_image_resizer_url('https://d230gea5qwdu36.cloudfront.net' + settings.metadata.image)
			: 'https://ncaatickets.com/img/share.jpg';

		this.get_championships();

		this.setMetaSidearm(title, [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'og:title',
				content: title,
			},
			{
				name: 'og:description',
				content: description,
			},
			{
				name: 'twitter:title',
				content: title,
			},
			{
				name: 'twitter:description',
				content: description,
			},
			{
				name: 'og:image',
				content: image,
			},
			{
				name: 'twitter:image',
				content: image,
			},
		]);
	},
};
</script>
